.image-post-container {
  margin: 2rem auto auto auto;
}
.image-size {
  height: 337px;
  object-fit: cover;
  margin: auto auto 1rem auto;
}
.image-post-title {
  margin-bottom: 1rem;
}
.username-style {
  word-break: break-word;
}