.text-content {
  margin: 2rem;
}
.text-post-title {
  margin-bottom: 1rem;
}

.text-post-container {
  margin: 2rem auto auto auto;
}
